import Button from "../Button";
import Form from "../form/Form";
import FormItem from "../form/FormItem";
import Input from "../Input";
import SimpleModal from "../SimpleModal";

import useForm from "../../../hooks/useForm";

export default function UrlInputModal({ open, onSubmit, onClose }) {

    const urlForm = useForm();

    const handleSubmit = (values) => {
        onSubmit(values);
    };

    return open ? (
        <SimpleModal
            open={open}
            maxWidth="xs"
        >
            <Form form={urlForm} onFinish={handleSubmit}>
                <div>
                    <FormItem
                        name="name"
                        label="Identificador"
                        required
                    >
                        <Input placeholder="botâo-redireciona-marketplace" />
                    </FormItem>
                    <FormItem
                        className="mt-4"
                        name="url"
                        label="Link do Botão"
                        required
                    >
                        <Input placeholder="https://exemplo.com" />
                    </FormItem>
                </div>

                <div className="mt-6 flex justify-end gap-3">
                    <Button
                        type="secondary"
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        Aplicar
                    </Button>
                </div>
            </Form>
        </SimpleModal>
    ) : null;
}
