import api from "../api";
import loginService from "../login.service";
import documentsService from "../solicitation/documents.service";

const buildFilter = filters => {
    const filter = Object.entries(filters).map(([key, value]) => ({
        name: key,
        value,
    }));

    return filter.filter(filter =>
        (Array.isArray(filter.value) && filter.value.length) || (!Array.isArray(filter.value) && filter.value !== null)
    );
}

export const findAll = async (filters = {}, pagination) => {

    const filter = buildFilter(filters);

    return api.post({
        type: "user",
        service: `marketing/blog/filter`,
        data: {
            filter,
            pagination,
        }
    })
        .then(res => res.data || {});
}

export const findMetrics = async (filters = {}) => {

    const filter = buildFilter(filters);

    return api.post({
        type: "user",
        service: `marketing/blog/metrics`,
        data: {
            filter,
        }
    })
        .then(res => res.data || []);
}

export const findById = async (id) =>
    api.get({ type: "user", service: `marketing/blog/${id}` })
        .then(res => (res.data || {}));

export const findBySlug = async (slug) =>
    api.get({ type: "user", service: `marketing/blog/slug/${slug}` })
        .then(res => (res.data || null));

export const findBlogTags = async () =>
    api.get({ type: "user", service: `marketing/blog/tags` })
        .then(res => (res.data || []));

export const findBlogAuthors = async () =>
    api.get({ type: "user", service: `marketing/blog/authors` })
        .then(res => (res.data || []));

export const createNewBlog = async () => {

    const user = loginService.getUserAuthentication();

    const initialBlogConfig = {
        title: 'Novo Artigo',
        status: 'draft',
        userId: user.id,
    };

    return api.post({ type: "user", service: `marketing/blog/`, data: initialBlogConfig })
        .then(res => res.data || { success: false });
}

export const edit = async (id, blogChanges) =>
    api.put({
        type: "user",
        service: `marketing/blog/${id}`,
        data: mapValues(blogChanges),
    });

const mapValues = (data) => ({
    title: data.title,
    content: data.content,
    status: data.status,
    headerCta: data.headerCta,
    footerBanner: data.footerBanner,
    tags: data.tags ? JSON.stringify(data.tags) : undefined,
    readingTime: data.readingTime,
    published: data.published,
    ebook: data.ebook,
    slug: data.slug,
    seoInformation: data.seoInformation,
    deleted: data.deleted,
});

const createCoverEntry = async (fileData, blogId) =>
    api.post({ type: 'user', service: `marketing/blog/${blogId}/blog-cover`, data: fileData })
        .then(({ data }) => data);

const updateBlogCover = async (file, blogId) => {

    const fileData = {
        name: file.name,
        size: file.size,
        fileType: file.type
    };

    const response = await createCoverEntry(fileData, blogId).catch(() => { });

    if (!response.success) throw Error({ message: response.message || 'Ocorreu um problema, por favor tente novamente mais tarde' });

    const upload = await documentsService.uploadFile(file, response.signedRequest, { ...fileData, document: { name: fileData.name } });

    if (!upload.success) {
        return { success: false };
    }

    return { success: true, coverImage: response.coverImage };
}

const BlogsService = {
    findAll,
    findById,
    findBySlug,
    createNewBlog,
    edit,
    findBlogTags,
    findBlogAuthors,
    findMetrics,
    updateBlogCover,
}

export default BlogsService;
