import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';

import {
    Grid, Typography, CircularProgress, FormControl, Tooltip,
    Collapse, FormGroup, Checkbox, TextField,
    IconButton, useTheme
} from '@material-ui/core';

import FormField from '../FormField';
import SelectArea from './fields/select.area.component';
import SelectModality from './fields/select.modality.component';
import RequestCalculationServices from './calculation/request.calculation.services.component';
import requestFieldsConfig from './fields/request.fields.config';

import requestServicesService from '../../domain/services/solicitation/request.services.service';
import loginService from '../../domain/services/login.service';
import customerService from '../../domain/services/customer/customer.service';
import requestFieldsService from '../../domain/services/request/request.fields.service';
import solicitationService from '../../domain/services/solicitation/solicitation.service';
import requestService from '../../domain/services/request/request.service';
import requestCalculationService from '../../domain/services/solicitation/request.calculation.service';

import communicationProvider from '../../providers/communication.provider';
import modalities from '../../domain/constant/modalities.constant';
import stepsSolicitation from '../../domain/constant/steps.solicitation.constant';
import { requestServices, onlyAdditionalServicesKey, servicesByTypeKey } from '../../domain/constant/request.services.constant';
import SimpleModal from '../shared/SimpleModal';
import { Close, Info, InfoOutlined } from '@material-ui/icons';

const RequestMainInformation = ({
    request = {}, setRequest, setValue, setMultipleValues, refresh, mainRequestId, setAlert,
    isModal, isEdit, fieldsWithError = [], fieldProps, openEditModal, setIsLoading, hearingAnalysisModalCallback = {}, initialValue = null
}) => {

    const serviceInfo = requestServices.structure[request.mainServiceKey] || requestServices.structure[request.serviceKey] || requestServices.structure[request.modalityType] || {};

    const theme = useTheme();

    const [customers, setCustomers] = useState([]);

    const [allServices, setAllServices] = useState([]);
    const [additionalServices, setAdditionalServices] = useState([]);
    const [productionServices, setProductionServices] = useState([]);

    const [isFetchingServices, setIsFetchingServices] = useState(false);
    const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
    const [showServiceInfoModal, setShowServiceInfoModal] = useState(false);

    const { promiseInProgress } = usePromiseTracker();

    hearingAnalysisModalCallback.current = addHearingAnalysisToHearing;

    const inputStyles = {
        titleSize: 15,
        titleWeight: 600,
        spacing: 10,
    };

    const selectPaddingStyle = {
        paddingTop: 3.5,
        paddingBottom: 2.5,
        paddingLeft: 4,
    };

    const formItemsGap = 32;

    const checkAndFindProductionServices = () => {
        const serviceId = request.mainId || request.id;

        if (!serviceId) return;

        return findProductionServices(serviceId);
    }

    const user = loginService.getUserAuthentication()

    useEffect(() => {
        if (!request.mainServiceId || (allServices || []).length == 0)
            return

        checkAndFindProductionServices();

        getAdditionalServices(request)

    }, [request.mainServiceId, allServices]);

    useEffect(() => findAllServices(), []);

    useEffect(() => {
        if (!initialValue) return;

        setRequest({ ...request, mainServiceKey: initialValue });
        handleChangeMainService({ target: { value: initialValue } });

    }, [allServices])

    useEffect(() => {

        if (isModal && !request.customerId)
            return findCustomers();

        return findCustomers([{ id: 'id', value: request.customerId }]);

    }, [request.customerId])

    const findProductionServices = async (mainRequestId) => {
        return trackPromise(
            requestServicesService.findProductionServices(mainRequestId)
                .then((response) => setProductionServices(response.data))
        );
    }

    const findAllServices = async () =>
        requestServicesService
            .findAll()
            .then(mapServices);

    const mapServices = (services) => {
        setAllServices(
            [...services]
                .sort((a, b) => a.serviceDevelopmentPriority < b.serviceDevelopmentPriority ? -1 : 1)
                .sort((a, b) => a.typeId < b.typeId ? -1 : 1)
        )

        return services;
    }

    const findCustomers = async (filters = []) => {

        if (!loginService.isInternal())
            return;

        setIsFetchingCustomers(true);

        await customerService.findByFilter({
            filters: [...filters],
            pageIndex: 0,
            pageSize: 50,
            sortBy: [{ id: 'name', desc: false }]
        })
            .then(data => setCustomers(data.results || []))
            .catch((error) => console.log(error))
            .finally(() => setIsFetchingCustomers(false));
    }

    const getSelectedServices = (requestServiceFlag, checked, requestData = request) => requestServicesService.getSelectedServices(allServices, requestData, isEdit, requestServiceFlag, checked);

    const handleChangeRequestServices = async (requestServiceFlag, checked, service) => {

        if (!isEdit) {
            const changes = getRequestChangesOnEditServices(requestServiceFlag, checked);
            setMultipleValues({ ...changes, areaId: null, forwardCalculationServiceId: null, calculationServiceId: null });
            return;
        }

        if (!checked && productionServices.length == 1) {
            showLastServiceWarning(service.name);
            return;
        }

        const checkIfNewServicesNeedNewFields = [
            {
                services: [requestServices.CALCULATION, requestServices.PETITION],
                action: () => requestServiceFlag == requestServices.structure.analysis.databaseKey && !checked || requestServiceFlag == requestServices.structure.petition.databaseKey && checked,
            },
            {
                services: [requestServices.PETITION],
                action: () => requestServiceFlag == requestServices.structure.analysis.databaseKey && !checked,
            },
            {
                services: [requestServices.ISSUEPAYMENTFEE, requestServices.PETITION],
                action: () => requestServiceFlag == requestServices.structure.petition.databaseKey && checked || requestServiceFlag == requestServices.structure.issuePaymentFee.databaseKey && checked,
            },
            {
                services: [requestServices.ISSUEPAYMENTFEE, requestServices.CALCULATION],
                action: () => requestServiceFlag == requestServices.structure.calculation.databaseKey && checked || requestServiceFlag == requestServices.structure.issuePaymentFee.databaseKey && checked,
            },
            {
                services: [requestServices.ISSUEPAYMENTFEE, requestServices.ANALYSIS],
                action: () => requestServiceFlag == requestServices.structure.analysis.databaseKey && checked || requestServiceFlag == requestServices.structure.issuePaymentFee.databaseKey && checked,
            }
        ]

        const newSelectedServices = getSelectedServices(requestServiceFlag, checked);
        const checkAction = checkIfNewServicesNeedNewFields.find(obj => obj.services.length == newSelectedServices.length && obj.services.every(s1 => newSelectedServices.some(s2 => s2.key == s1)))?.action;

        if (checkAction && checkAction()) {

            const serviceStrategy = {
                [requestServices.structure.petition.databaseKey]: {
                    modalityId: null,
                    serviceId: requestServices.structure.petition.id
                },
                [requestServices.structure.issuePaymentFee.databaseKey]: {
                    modalityId: modalities.issuePaymentFee.id,
                    serviceId: requestServices.structure.issuePaymentFee.id
                },
                [requestServices.structure.analysis.databaseKey]: {
                    modalityId: modalities.analysis.id,
                    serviceId: requestServices.structure.analysis.id
                },
                [requestServices.structure.calculation.databaseKey]: {
                    editingService: 'calculation',
                    calculationServiceId: null,
                    modalityId: modalities.calculation.id,
                    serviceId: requestServices.structure.calculation.id
                }
            };

            const changes = serviceStrategy[requestServiceFlag] || serviceStrategy[requestServices.structure.petition.databaseKey];

            openEditModal(
                { ...changes, fields: {} },
                async (requestChanges, fieldsChanges) => {
                    await Promise.all([
                        editRequestValue(requestChanges, fieldsChanges, requestServiceFlag),
                        Promise.all(Object.values(fieldsChanges).filter(f => f.id).map(field => requestFieldsConfig.editField(request, field, true)(field))),
                        requestServicesService.editService(request.mainId || request.id, { fieldName: requestServiceFlag, fieldValue: requestChanges.forwardCalculationServiceId || checked })
                    ]).then(() => findProductionServices(request.mainId || request.id));
                }
            )

            return
        }

        const isAddingCalculation = requestServiceFlag == requestServices.structure.calculation.databaseKey && checked;
        if (isAddingCalculation) {
            openEditModal({ editingService: 'calculation', calculationServiceId: null }, addCalculation);
            return
        }

        changeService(getWarningDetails(checked, service.name), requestServiceFlag, checked);

    }


    const editRequestValue = async (requestChanges, fieldsChanges, requestServiceFlag) => solicitationService.editValue(user.source === 'customer' ? request?.previousServiceId || request.id : request.id, {
        forwardModalityId: requestServiceFlag == requestServices.structure.issuePaymentFee.databaseKey ? null : requestChanges.modalityId,
        processNumber: fieldsChanges.processNumber?.value,
        state: requestChanges.state,
        city: requestChanges.city,
        citationDate: requestChanges.citationDate,
        servicePublishedAt: requestChanges.servicePublishedAt || null,
        freeJustice: requestChanges.freeJustice,
        processNumber: requestChanges.processNumber,
        hearingDateTime: requestChanges.hearingDateTime,
        forwardPremiumLink: requestChanges.linkPremiumFaciliter,
    })

    const addCalculation = async ({ forwardCalculationServiceId }) =>
        trackPromise(
            requestServicesService
                .editService(request.mainId || request.id, {
                    fieldName: 'forwardCalculationServiceId',
                    fieldValue: forwardCalculationServiceId
                }).finally(() => {
                    refresh();
                    findProductionServices(request.mainId || request.id);
                })
        );

    const handleChangeMainService = async (element) => {

        const selectedKey = element.target.value;

        const selectedService = allServices.find(s => s.key == selectedKey);

        if (!selectedService)
            return;

        const changes = getRequestChangesOnEditServices(selectedService.requestDatabaseFlag, true, {}, true);

        setRequest({
            id: request.id,
            customerId: request.customerId,
            mainServiceId: selectedService.id,
            mainServiceKey: selectedService.key,
            ...changes,
            modalityId: changes.modalityId || selectedService.modalityId,
        });

    }

    const getWarningDetails = (isAdding, serviceName) => ({
        title: `${isAdding ? 'Adicionar' : 'Remover'} ${serviceName}`,
        text: isAdding ?
            `Ao confirmar, o valor do serviço ${serviceName} será descontado do seu pacote e será adicionado na solicitação. Deseja continuar?`
            : `Ao confirmar, o serviço de ${serviceName} será removido. O valor do serviço retornará para seu pacote. Deseja continuar?`
    })


    const showLastServiceWarning = (serviceName) =>
        Swal.fire({
            title: `Não é possível remover serviço`,
            text: `O serviço de ${serviceName} é o único serviço em sua solicitação. Para prosseguir, é necessário adicionar mais serviços. Se você deseja cancelar a solicitação, você pode arquivá-la a qualquer momento.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'Entendi',
            showConfirmButton: false,
        }).finally(() => {
            setIsLoading(false);
            setIsFetchingServices(false);
        });

    const changeService = (warning, requestServiceFlag, checked) => {
        trackPromise(
            Promise.resolve(
                Swal.fire({
                    title: warning.title,
                    text: warning.text,
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Continuar',
                    closeOnClickOutside: false,
                    reverseButtons: true,
                })
                    .then(res => {
                        setIsLoading(true);
                        setIsFetchingServices(true);
                        if (!res.isConfirmed)
                            return

                        return requestServicesService.editService(mainRequestId, { fieldName: requestServiceFlag, fieldValue: checked })
                    })
                    .then(refresh)
                    .catch(err => communicationProvider.getErrorMessage(err, setAlert))
                    .finally(() => {
                        setIsLoading(false);
                        setIsFetchingServices(false);
                        findProductionServices(request.mainId || request.id);
                    })
            ));
    }

    const handleChangeCalculationType = async (data) => {

        if (request.serviceKey != requestServices.CALCULATION) {
            setAlert('Só é possível alterar o tipo de cálculo durante a produção do serviço!', 'error');
            return;
        }

        return requestCalculationService
            .update(data.requestId, data)
            .then(refresh);
    }

    const getRequestChangesOnEditServices = (requestServiceFlag, checked, requestData = request, isChangingMainService = false) => {

        const newSelectedServices = getSelectedServices(requestServiceFlag, checked, requestData);

        const newServiceFlag = requestServiceFlag == 'forwardCalculationServiceId' ? 'needCalculation' : requestServiceFlag;

        if (!newSelectedServices.length)
            return {
                [newServiceFlag]: checked,
                serviceId: null,
                modalityId: null
            }

        const servicesSortedByPriority = allServices
            .filter(s => newSelectedServices.some(nss => nss.id == s.id))
            .sort((a, b) => b.registrationPriority - a.registrationPriority);

        let firstService = servicesSortedByPriority[0];
        if (requestData.modalityId == modalities.notion.id)
            firstService = servicesSortedByPriority.find(s => s.key == modalities.notion.key);

        const serviceModality = modalities[firstService.key];


        let servicesDatabaseFlags = {}

        if (isChangingMainService)
            servicesDatabaseFlags = allServices.reduce((acc, service) => ({ ...acc, [service.requestDatabaseFlag]: null }), {});

        return {
            ...servicesDatabaseFlags,
            [newServiceFlag]: checked,
            serviceId: firstService.id,
            serviceKey: firstService.key,
            modalityId: serviceModality?.id || null,
            modalityKey: serviceModality?.key || null
        }
    }

    const getAdditionalServices = (request) => {

        const requestMainService = allServices.find(s => s.id == request.mainServiceId);

        const newAdditionalServices = allServices.filter(s => (requestMainService.additionalServicesIds || []).includes(s.id));
        const finalServiceOption = isEdit ? [requestMainService] : [];

        setAdditionalServices([...finalServiceOption, ...newAdditionalServices]);
    }

    const canShowSelectModality = (request) => (
        !!request.areaId
        && (
            request.serviceId == requestServices.structure.petition.id
            || (!!request.forwardToPetition && !isEdit)
        )
    );

    const canShowOnlyRegisterField = (request) =>
        (isModal || !isEdit && !!request.mainServiceId);

    function addHearingAnalysisToHearing() {
        const service = additionalServices.find(s => s.key === modalities.hearingAnalysis.key);
        const serviceFlag = service.requestDatabaseFlag;
        handleChangeRequestServices(serviceFlag, true, service);
        return Promise.resolve();
    }

    const loadingField = () => (
        <Grid
            container justifyContent='center'
            style={{ paddingTop: 10, marginBottom: 15 }}
        >
            <CircularProgress size={22} />
        </Grid>
    )

    const checkAndReturnIdIfHasPetition = [requestServices.structure.petition.id, requestServices.structure.analysis.id].includes(request.serviceId) ? request.serviceId : request.mainServiceId;

    const getServicesOptions = () => {

        if (isFetchingServices || (isEdit && !request.id) || (!!request.mainServiceId && !allServices.length))
            return loadingField();

        const tooltipByServiceKey = {
            'analysis': 'Inclua esse serviço em sua solicitação para que a equipe da Facilita identifique a petição cabível ao caso. Atenção: Não respondemos perguntas neste serviço apenas identificamos o tipo de petição, caso queira um estudo completo, selecione como serviço principal PETIÇÃO e escolha a modalidade PARECER.',
            'calculation': 'Sua solicitação precisará de algum tipo de cálculo? Selecione essa opção que a equipe da Facilita entregará os cálculos junto com a petição',
            'petition': 'Receba minutas de petição para todas as áreas do Direito',
            'hearingAnalysis': 'Contratou o serviço de audiência? Insira o serviço de análise de audiência para o advogado responsável realizar estudo do caso e indicar as perguntas que precisam ser realizadas em audiência.',
            'issuePaymentFee': 'Emissão de documentos para o pagamento de custas processuais ou despesas relacionadas a um processo judicial.',
        }

        return (
            <FormControl>
                <FormGroup>
                    {additionalServices.map((service, index) => (

                        <div
                            key={service.id}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                marginTop: 8,
                                marginBottom: index === additionalServices.length - 1 ? 0 : 8,
                            }}
                        >
                            <Checkbox
                                checked={requestServicesService.checkRequestHasService(service, request)}
                                onChange={(e) => {
                                    handleChangeRequestServices(service.requestDatabaseFlag, e.target.checked, service)
                                }}
                                name={service.name}
                                style={{ padding: 0, paddingRight: 8 }}
                            />
                            <Tooltip
                                title={<p style={{ fontSize: 11 }}>{tooltipByServiceKey[service.key] || ''}</p>}
                                key={service.id}
                            >
                                <Typography style={{ fontSize: 13 }}>{service.name}</Typography>
                            </Tooltip>
                        </div>
                    ))}
                </FormGroup>
            </FormControl>
        )
    }

    const getAreaSelectLabel = () => {
        const initialText = 'Qual a área do Direito';

        const serviceName = serviceInfo?.name || 'Solicitação';
        const serviceGender = serviceInfo?.grammaticalGender;
        const article = serviceGender === 'masculine' ? 'do' : 'da';

        const fullLabel = `${initialText} ${article} ${serviceName}?`;
        return fullLabel;
    }

    const getMainServiceHelperText = () => {
        const initialText = 'Clique aqui para saber mais sobre ';

        const serviceName = serviceInfo?.name || 'Solicitação';
        const serviceGender = serviceInfo?.grammaticalGender;
        const article = serviceGender === 'masculine' ? 'o' : 'a';

        const fullLabel = `${initialText} ${article} ${serviceName}`;
        return fullLabel;
    }

    const getMainServiceOptions = (request) => {

        if (!allServices.length)
            return loadingField();

        let services = allServices.filter(service => service.id == request.mainServiceId);
        let serviceValue = services[0];

        return (
            <>
                <FormField
                    field="Qual serviço deseja que seja feito?"
                    type='select'
                    required
                    currentValue={request.mainServiceKey || serviceValue?.key}
                    onChange={(_, value) => handleChangeMainService({ target: { value } })}
                    name="service"
                    options={
                        allServices
                            .filter(s => !onlyAdditionalServicesKey.includes(s.key))
                            .map((service) => ({
                                value: service.key,
                                name: service.name
                            }))}
                    placeholder='Selecione o serviço da solicitação'
                    disabled={fieldProps.disabled || isEdit}
                    titleColor={'#131313'}
                    style={selectPaddingStyle}
                    {...inputStyles}
                />
                {serviceInfo.name && (
                    <div className='mt-2'>
                        <button
                            className='hover:brightness-125 text-sm'
                            onClick={() => setShowServiceInfoModal(true)}
                            style={{ color: theme.palette.primary.main }}
                        >
                            <InfoOutlined size="small" style={{ fontSize: 18, marginBottom: 3 }} /> {getMainServiceHelperText()}
                        </button>
                    </div>
                )}
            </>
        )
    }

    return (
        <Grid
            container direction="column"
            style={requestFieldsService.checkDisableFieldsStyle(request, isEdit)}
        >

            {(loginService.isInternal() && canShowOnlyRegisterField(request)) && (
                <Grid style={{ marginBottom: formItemsGap }}>
                    <FormField
                        field='Qual o cliente dono da solicitação?'
                        name='customerId'
                        type='autocomplete'
                        placeholder='Digite as 3 primeiras letras do nome para que o cliente seja buscado'
                        titleColor={'#131313'}
                        onError={fieldsWithError.includes('customerId')}
                        currentValue={request.customerId} required
                        onChange={setValue}
                        disableClearable
                        options={customers.map(customer => ({ ...customer, value: customer.id }))}
                        onInputChange={(e, value, event) => {
                            if (event != 'input' || value.length < 3 || isFetchingCustomers)
                                return

                            findCustomers([{ id: 'name', value }]);
                        }}
                        disabled={isEdit}
                        {...inputStyles}
                    />
                </Grid>
            )}

            {canShowOnlyRegisterField(request) && (
                <Grid container direction='column' style={{ marginBottom: formItemsGap - 12 }}>
                    {getMainServiceOptions(request)}
                </Grid>
            )}

            {isEdit &&
                <Grid container direction='column' style={{ marginBottom: formItemsGap }}>

                    <Typography
                        gutterBottom
                        style={{
                            color: fieldsWithError.includes('mainServiceId') ? '#E01919' : '#131313',
                            fontSize: 16,
                            fontWeight: 600
                        }}
                    >
                        Serviços da solicitação

                        {promiseInProgress && <CircularProgress style={{ width: 16, height: 16, marginLeft: 15 }} />}
                    </Typography>

                    <FormControl>
                        <FormGroup>
                            {additionalServices.map((service) => (

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                    <Checkbox
                                        checked={requestServicesService.isRequestService(service, productionServices)}
                                        onChange={(e) => {
                                            handleChangeRequestServices(service.requestDatabaseFlag, e.target.checked, service)
                                        }}
                                        name={service.name} disabled={request.status == stepsSolicitation.CONCLUDED}
                                    />
                                    <Typography style={{ fontSize: 13 }}>{service.name}</Typography>
                                </div>

                            ))}
                        </FormGroup>
                    </FormControl>
                </Grid>
            }

            <Collapse in={!isEdit && additionalServices.length > 0}>
                <Grid container direction='column' style={{ marginBottom: formItemsGap }}>
                    <Typography
                        style={{
                            fontSize: 15,
                            fontWeight: 600,
                            lineHeight: 1.2
                        }}>
                        Gostaria de incluir serviços adicionais à sua solicitação?
                        <Typography
                            gutterBottom
                            style={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: '#131313aa',
                                marginTop: 4
                            }}>
                            Caso não queira incluir outro serviço, basta não marcar nenhuma opção.
                        </Typography>
                    </Typography>

                    {getServicesOptions()}

                </Grid>
            </Collapse>

            <Collapse in={!!getSelectedServices().length}>
                <Grid style={{ marginBottom: formItemsGap }}>
                    <SelectArea
                        label={getAreaSelectLabel()}
                        areaId={request.areaId}
                        selectedServices={getSelectedServices().map(s => s.id)}
                        services={allServices}
                        setValue={(name, value) => setMultipleValues({ [name]: value, fields: {} })}
                        onError={fieldsWithError.includes('areaId')}
                        fieldProps={{
                            titleColor: '#131313',
                            style: selectPaddingStyle,
                            ...inputStyles
                        }}
                        style={selectPaddingStyle}
                        isEdit={isEdit}
                        openEditModal={openEditModal}
                    />
                </Grid>
            </Collapse>

            <Collapse in={canShowSelectModality(request)}>
                <Grid style={{ marginBottom: formItemsGap }}>
                    <SelectModality
                        label='Qual o tipo de petição?'
                        disabled={request.forwardToAnalysis}
                        modalityId={request.modalityId}
                        setMultipleValues={(values) => setMultipleValues({ ...values, fields: {} })}
                        serviceId={checkAndReturnIdIfHasPetition}
                        fieldProps={{
                            titleColor: '#131313',
                            style: selectPaddingStyle,
                            ...inputStyles
                        }}
                        isEdit={isEdit}
                        onError={fieldsWithError.includes('modalityId')}
                        openEditModal={openEditModal}
                    />
                </Grid>
            </Collapse>

            <Collapse in={!!request.areaId && ((!!request.needCalculation && !isEdit) || !!request.forwardCalculationServiceId || !!request.calculationServiceId)}>
                {!!request.areaId && ((!!request.needCalculation && !isEdit) || !!request.forwardCalculationServiceId || !!request.calculationServiceId) && (
                    <Grid style={{ marginBottom: formItemsGap }}>
                        <RequestCalculationServices
                            label='Tipo de cálculo'
                            request={request}
                            requestId={request.id}
                            areaId={request.areaId}
                            calculationServiceId={request.serviceKey == 'calculation' ? request.calculationServiceId : request.forwardCalculationServiceId}
                            isEdit={isEdit}
                            onSave={isEdit ? handleChangeCalculationType : false}
                            setFieldValue={setValue}
                            fieldProps={{
                                titleColor: fieldsWithError.includes('calculationServiceId') || fieldsWithError.includes('forwardCalculationServiceId') ? '#E01919' : '#131313',
                                ...inputStyles
                            }}
                        />
                    </Grid>
                )}
            </Collapse>

            <Collapse
                in={isModal && (request.forwardToAnalysis || request.needCalculation || [5, 6, 9, 8, 11, 12, 15, 16].includes(request.modalityId))}
            >
                <Tooltip title={<p style={{ fontSize: 11 }}>Ao informar o número do processo, a plataforma verifica de forma automática se existe alguma solicitação com o mesmo número de processo. Se houver, serão transportadas as informações da solicitação anterior para maior rapidez no seu cadastro</p>}>
                    <Typography
                        style={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: fieldsWithError.includes('processNumber') ? '#E01919' : '#131313',
                        }}>
                        Já existe processo? Se sim, informe o número
                    </Typography>
                </Tooltip>
                {!requestService.hasRequiredProcessNumber(request.modalityKey) && (
                    <Typography
                        gutterBottom
                        style={{
                            fontSize: 12,
                            fontWeight: 500
                        }}>
                        * Se não há número de processo, ignore este campo
                    </Typography>
                )}
                {isModal && (
                    <div style={{ display: 'flex', marginBottom: formItemsGap }}>
                        <TextField
                            size="small"
                            fullWidth
                            id="processNumber"
                            variant="outlined"
                            value={request.processNumber || ''}
                            onChange={(e) => setRequest({ ...request, processNumber: e.target.value })}
                            placeholder='Insira o número do processo'
                        />
                    </div>
                )}
            </Collapse>

            <SimpleModal
                open={showServiceInfoModal}
                onClose={() => setShowServiceInfoModal(false)}
                maxWidth='xs'
            >
                <div className="flex text-base gap-2">
                    <div>
                        <Info style={{ color: "#376fd0" }} />
                    </div>
                    <div>
                        <div className="flex justify-between font-semibold">
                            <div className='mt-[3px]'>
                                {serviceInfo?.name || 'Solicitação'}
                            </div>
                            <IconButton
                                aria-label="close"
                                size='small'
                                onClick={() => setShowServiceInfoModal(false)}
                            >
                                <Close />
                            </IconButton>
                        </div>
                        <div className="mt-3 text-[#131313] text-sm" style={{ lineHeight: 1.6 }}>
                            {serviceInfo?.description || 'Ocorreu um problema ao carregar as informações do serviço'}
                        </div>
                    </div>
                </div>
            </SimpleModal>

        </Grid>
    );
}

export default RequestMainInformation;