import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';

import { CircularProgress, Button, DialogActions, DialogTitle, Dialog, DialogContent, Grid } from '@material-ui/core';

import RequestCalculationServices from './requests/calculation/request.calculation.services.component';
import SelectModality from './requests/fields/select.modality.component';
import SelectArea from './requests/fields/select.area.component';
import LoadingProgress from './LoadingProgress';

import requestFieldsConfig from './requests/fields/request.fields.config';
import communicationProvider from '../providers/communication.provider';

import solicitationService from '../domain/services/solicitation/solicitation.service';
import { requestServices } from '../domain/constant/request.services.constant';
import requestServicesService from '../domain/services/solicitation/request.services.service';
import SimpleModal from './shared/SimpleModal';

const EditFieldsModal = ({
    open, onClose, initialValues = {}, request, setAlert, refresh, onSave
}) => {

    const [requestChanges, setRequestChanges] = useState({});

    const [services, setServices] = useState([]);
    const [fields, setFields] = useState({});

    const [fieldsWithError, setFieldsWithError] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);

    const fieldProps = {
        cleanTitle: true,
        titleSize: 15,
        disabled: isSubmiting,
        titleWeight: 600,
        spacing: 10,
    }

    useEffect(() => {

        if (!open)
            return;

        getFields(initialValues.modalityId, initialValues.areaId);

    }, [initialValues.areaId, initialValues.modalityId]);

    useEffect(() => {

        if (!request.serviceTypeId)
            return;

        findServices();

    }, [request.serviceTypeId]);

    const findServices = async () =>
        requestServicesService
            .findAll()
            .then(res => {
                const availableServices = res.filter(s => s.typeId == request.serviceTypeId);
                setServices(availableServices);
            });

    const getFields = async (modalityId = request.modalityId, areaId = request.areaId, changeRequest = true) => {

        setIsLoading(true);

        return requestFieldsConfig
            .getFields(areaId, modalityId, request, true)
            .then((fields) => {
                setFields(fields);

                if (!changeRequest)
                    return fields

                setRequestChanges({
                    ...request,
                    ...initialValues,
                    fields: Object.values(fields)
                        .reduce((acc, cur) => ([...acc, ...cur]), [])
                        .map(field => ({
                            ...field,
                            value: request[field.key] || (request.fields || {})[field.key]?.value,
                        }))
                        .reduce((acc, cur) => ({ ...acc, [cur.key]: cur }), {})
                });

                return fields
            })
            .finally(() => setIsLoading(false));
    }

    const editModalityOrArea = async (name, value) => {

        const modalityId = (name == 'modalityId') ? value : requestChanges.modalityId;
        const areaId = (name == 'areaId') ? value : requestChanges.areaId;

        const fields = await getFields(modalityId, areaId, false);

        setRequestChanges({
            ...requestChanges,
            [name]: value,
            fields: Object.values(fields)
                .reduce((acc, cur) => ([...acc, ...cur]), [])
                .map(field => ({
                    ...field,
                    value: request[field.key] || (request.fields || {})[field.key]?.value,
                }))
                .reduce((acc, cur) => ({ ...acc, [cur.key]: cur }), {})
        });
    }

    const clearModalData = () => {
        setFields({});
        setRequestChanges({});
    }

    const handleClose = () => {
        onClose();
        clearModalData();
    }

    const handleSave = async () => {

        setIsSubmiting(true)

        const { fields: newRequestFields, ...newRequest } = requestChanges;

        await handleEditService(newRequest, newRequestFields);
    }

    const validateEditService = (requestChanges, fieldsChanges) => {

        if (initialValues.serviceId == requestServices.structure.petition.id && !requestChanges.modalityId) {
            setFieldsWithError(['modalityId'])
            setIsSubmiting(false)
            return false
        }

        if (initialValues.editingService == 'calculation' && !requestChanges.calculationServiceId && !requestChanges.forwardCalculationServiceId) {
            setFieldsWithError(['calculationServiceId'])
            setIsSubmiting(false)
            return false
        }

        const fieldWithError = [
            ...(fields['processInformation'] || []),
            ...(fields['factsAndFundamentals'] || [])
        ]
            .filter(f => f.key != 'state')
            .find(fieldToCheck => {

                const fieldKey = Object.keys(fieldsChanges).find(fcKey => fieldsChanges[fcKey].key == fieldToCheck.key);

                const field = fieldsChanges[fieldKey];

                if (!field || !fieldsChanges[fieldKey])
                    return false

                return field.required && !(field.value || requestChanges[fieldKey]);
            })

        if (!!fieldWithError) {
            setFieldsWithError(fieldWithError.key)
            setIsSubmiting(false)
            return false
        }

        return true
    }

    const handleEditService = async (requestChanges, fieldsChanges) => {

        const isValid = validateEditService(requestChanges, fieldsChanges);

        if (!isValid)
            return

        const action = onSave ? () => onSave(requestChanges, fieldsChanges) : () => solicitationService.editModality(request.id, requestChanges, fieldsChanges);

        Promise.resolve(action())
            .then(refresh)
            .then(handleClose)
            .then(() => showAlertDialog(
                "Serviço editado com sucesso!", "success",
                "O serviço foi editado com sucesso. Continue acompanhando o progresso da solicitação"
            ))
            .catch((err) => communicationProvider.getErrorMessage(err, setAlert, 'Erro ao editar serviço'))
            .finally(() => setIsSubmiting(false));
    }

    const showAlertDialog = async (title, icon, text) =>
        swal({
            title, text, icon,
            buttons: {
                confirm: { text: 'Continuar', className: 'MuiButton-containedPrimary' }
            }
        });

    const setValue = (fieldName, value) =>
        setRequestChanges((item) => ({ ...item, [fieldName]: value }));


    const renderFields = (fieldGroup) => {

        if (!fields[fieldGroup]?.length)
            return;

        return fields[fieldGroup].map((field, index) => (
            <React.Fragment key={index}>
                {requestFieldsConfig.getComponentField({
                    field,
                    request: requestChanges,
                    setValue,
                    isEdit: true,
                    fieldProps,
                    fieldsWithError,
                    setAlert
                })}
            </React.Fragment>
        ));
    };

    const getSettingField = () => {

        if (initialValues.serviceId == requestServices.structure.petition.id)
            return (
                <Grid item xs={12}>
                    <SelectModality
                        modalityId={requestChanges.modalityId}
                        setMultipleValues={(values) => {
                            setFieldsWithError([]);
                            editModalityOrArea('modalityId', values.modalityId);
                        }}
                        serviceId={requestChanges.serviceId}
                        fieldProps={{
                            ...fieldProps,
                            style: {
                                paddingTop: 3.5,
                                paddingBottom: 2.5,
                                paddingLeft: 4,
                            }
                        }}
                        onError={fieldsWithError.includes('modalityId')}
                    />
                </Grid>

            );

        if (!!initialValues.areaId) {

            const selectedServices = requestServicesService.getSelectedServices(services, { ...request, ...requestChanges });

            return (
                <SelectArea
                    areaId={requestChanges.areaId}
                    services={services}
                    selectedServices={selectedServices.map(s => s.id)}
                    setValue={editModalityOrArea}
                    fieldProps={fieldProps}
                />
            )
        }

        return null;
    }

    const getCalculationField = () => {

        const calculationServiceId = request.forwardCalculationServiceId || request.calculationServiceId;

        if (initialValues.editingService != 'calculation' && !(initialValues.areaId && !!calculationServiceId))
            return null;

        const newCalculationServiceId = requestChanges.forwardCalculationServiceId || requestChanges.calculationServiceId;

        return (
            <Grid item style={{ width: '100%' }}>
                <RequestCalculationServices
                    request={requestChanges}
                    requestId={request.id}
                    areaId={requestChanges.areaId || request.areaId}
                    calculationServiceId={newCalculationServiceId || calculationServiceId}
                    setFieldValue={(...params) => {
                        setFieldsWithError([]);
                        setValue(...params);
                    }}
                    fieldProps={fieldProps}
                    onError={fieldsWithError.includes('calculationServiceId')}
                />
            </Grid>
        )
    }


    return (
        <SimpleModal
            open={open}
            disableBackdropClick
            title="Preencha os campos abaixo para editar sua solicitação"
            onClose={handleClose}
            maxWidth="sm"
        >
            <div className='flex flex-col gap-6'>

                {getSettingField()}

                {getCalculationField()}

                {isLoading ? (
                    <Grid item xs={12}>
                        <LoadingProgress />
                    </Grid>
                ) : (
                    <>
                        {renderFields('processInformation')}
                        {renderFields('factsAndFundamentals')}
                        {!!fields['premiumServices']?.length && (
                            <div>
                                <div style={{ fontSize: 15, fontWeight: 600, marginBottom: 16, lineHeight: 1.2 }}>
                                    Deseja solicitar um faciliter premium?
                                </div>
                                <div className='w-fit'>
                                    {renderFields('premiumServices')}
                                </div>
                            </div>
                        )}
                    </>
                )}

            </div>
            <div className='w-full relative h-10 mt-6 flex justify-end gap-3'>
                <Button
                    variant="contained" onClick={handleClose}
                    disabled={isSubmiting}
                >
                    Cancelar
                </Button>

                <Button
                    variant="contained" onClick={handleSave} color="primary"
                    disabled={isLoading || isSubmiting}
                >
                    Confirmar
                    {isSubmiting && (
                        <span>
                            <CircularProgress m={2} style={{
                                display: "flex", alignItems: "center", justifyContent: "center",
                                marginLeft: 10, width: 16, height: 16
                            }} />
                        </span>
                    )}
                </Button>
            </div>

        </SimpleModal>
    );
};

export default EditFieldsModal;
