import Quill from "quill";
import { useEffect, useRef, useState } from "react"

import 'quill/dist/quill.snow.css';
import { AddCircle, AddCircleOutline } from "@material-ui/icons";
import { ButtonBlot } from "./customFormats/ButtonBlot";
import UrlInputModal from "./UrlInputModal";
import { Tooltip } from "@material-ui/core";

export default function QuillEditor({
    onChange,
    value = '',
}) {

    const quillRef = useRef(null);
    const toolbarRef = useRef(null);

    const [quillInstance, setQuillInstance] = useState(null);

    const [customButtonPopupInfo, setCustomButtonPopupInfo] = useState(null);

    useEffect(() => {
        Quill.register(ButtonBlot);

        const quill = new Quill(quillRef.current, {
            theme: 'snow',
            modules: {
                toolbar: {
                    container: toolbarRef.current,
                    handlers: {
                        'cta-button': () => {
                            const range = quill.getSelection();
                            const selectedText = quill.getText(range?.index, range?.length);

                            if (range && selectedText) {
                                setCustomButtonPopupInfo({
                                    range,
                                    selectedText,
                                    type: 'primary',
                                });
                            }
                        },
                        'cta-button-secondary': () => {
                            const range = quill.getSelection();
                            const selectedText = quill.getText(range?.index, range?.length);

                            if (range && selectedText) {
                                setCustomButtonPopupInfo({
                                    range,
                                    selectedText,
                                    type: 'secondary',
                                });
                            }
                        },
                    }
                }
            }
        });

        quill.root.innerHTML = value;

        setQuillInstance(quill);
    }, []);

    useEffect(() => {
        if (!quillInstance) return;

        quillInstance.on('text-change', onContentChange);
        return () => quillInstance.off('text-change', onContentChange);
    }, [quillInstance, onChange, value]);

    const onContentChange = () => {
        const content = quillInstance.root.innerHTML;
        if (value && content === value) return;
        onChange(content);
    }

    const handleUrlSubmit = (values) => {
        quillInstance.format('button', {
            type: customButtonPopupInfo.type,
            text: customButtonPopupInfo.selectedText,
            url: values.url,
            name: values.name,
        });
        setCustomButtonPopupInfo(null);
    };

    const renderToolbar = () => (
        <div
            ref={toolbarRef}
            id="quill-toolbar"
            className="sticky bg-white z-10"
            style={{
                top: 65,
                borderBottom: '1px solid #ccc',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
            }}
        >
            <span className="ql-formats">
                <select className="ql-header" defaultValue="">
                    <option value="1">Título 1</option>
                    <option value="2">Título 2</option>
                    <option value="3">Título 3</option>
                    <option value="">Texto</option>
                </select>
            </span>
            <span className="ql-formats">
                <select className="ql-size" defaultValue="">
                    <option value="small">Pequeno</option>
                    <option value="">Médio</option>
                    <option value="large">Grande</option>
                    <option value="huge">Enorme</option>
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <button className="ql-underline"></button>
            </span>
            <span className="ql-formats">
                <select className="ql-align" defaultValue="">
                    <option value="">Normal</option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
            </span>
            <span className="ql-formats">
                <button className="ql-link"></button>
                <button className="ql-image"></button>
            </span>
            <span className="ql-formats">
                <Tooltip title={<span style={{ fontSize: 12 }}>Botão Primário para redirecionar o usuário.<br />Chama mais atenção que o botão secundário<br />Selecione o texto que deseja transformar em botão e clique aqui.</span>} placement="top">
                    <button className="ql-cta-button" type="button">
                        <AddCircle className="text-primary" />
                    </button>
                </Tooltip>

                <Tooltip title={<span style={{ fontSize: 12 }}>Botão Secundário para redirecionar o usuário.<br />Selecione o texto que deseja transformar em botão e clique aqui.</span>} placement="top">
                    <button className="ql-cta-button-secondary" type="button">
                        <AddCircleOutline className="text-primary" />
                    </button>
                </Tooltip>
            </span>
            <span className="ql-formats">
                <button className="ql-clean"></button>
            </span>
        </div>
    )

    return (
        <div className="w-full">
            <div className="relative">

                {renderToolbar()}

                <div ref={quillRef} className="bg-white w-full" />

                <UrlInputModal
                    open={!!customButtonPopupInfo}
                    onSubmit={handleUrlSubmit}
                    onClose={() => setCustomButtonPopupInfo(null)}
                />
            </div>
        </div>
    )
}